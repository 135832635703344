import React, {useState} from 'react';
import axios from 'axios';
import cairo from './Cairo.json';

function App() {
  const [data,setData] = useState({})
  const [location, setLocation] = useState('')
  const url = `https://api.openweathermap.org/data/2.5/weather?q=${location}&units=metric&appid=9fed4aef47612463b82cb67cb5c68924`
  const element = document.getElementById("demo");
  const searchLocation = (event) => {
    if (event.key === 'Enter') {
      axios.get(url).then((response) => {
        setData(response.data)
        console.log(response.data)
        element.style.display = 'none'
      })
      setLocation('')
    }
  }
  return (
    <div className="app"> 
      <div className='search'>
        <input value={location}
        onChange={event => setLocation(event.target.value)}
        // onClick={myFunction} 
        onKeyPress={searchLocation}
        placeholder='Enter Location'
        type="text"/>
      </div>
      <div className='container'>
        <div className='top'>
          <div className='location'>
            <p>{data.name}</p>
          </div>
          <div className='temp'>
            {data.main ? <h1>{data.main.temp.toFixed()}°C</h1> : null}
          </div>
          <div className='description'>
            {data.main ? <p>{data.weather[0].main}</p> : null}
          </div>
        </div>
      {data.name !== undefined && 
        <div className='bottom'>
          <div className='feels'>
          {data.main ? <p className='bold'>{data.main.feels_like.toFixed()}°C</p> : null}
            <p>Feels like</p>
          </div>
          <div className='humidity'>
          {data.main ? <p className='bold'>{data.main.humidity}%</p> : null}
            <p>Humidity</p>
          </div> 
          <div className='wind'>
          {data.wind ? <p className='bold'>{data.wind.speed.toFixed()}MPH</p> : null}
            <p>Wind Speed</p>
          </div>
        </div>
      }
      <div className="f2" id='demo' >
        <p>Cairo</p>
          <div className='f3'>
            <h1 className='bold'>{cairo.main.feels_like.toFixed()}°C</h1> 
            </div>
        <div className='f1'>
            <div className='feels'>
              <p className='bold'>{cairo.main.feels_like.toFixed()}°C</p> 
              <p>Feels like</p>
            </div>
            <div className='humidity'>
              <p className='bold'>{cairo.main.humidity}%</p>
              <p>Humidity</p>
            </div> 
            <div className='wind'>
              <p className='bold'>{cairo.wind.speed.toFixed()}MPH</p>
              <p>Wind Speed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
